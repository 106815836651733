import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import ring from "../../image/ring.png";
// import { Link } from "react-router-dom";
// import arrow from "../../image/arrow.png";

const HomeMissionStyle = styled.div`
  margin-bottom: 300px;
  .mission-content {
    display: flex;
    align-items: center;
  }
  .mission-left {
    width: 48%;
    margin-left: 50px;
  }
  .mission-head {
    display: flex;
    align-items: center;
    p {
      margin: 0;
      margin-left: 5px;
    }
  }
  .ring {
    img {
      width: 10px;
    }
  }
  .mission-right {
    width: 52%;
    p {
      white-space: pre-wrap;
    }
  }
  @media screen and (max-width: 1015px) {
    .mission-right {
      p {
        /* white-space: normal; */
        padding-left: 20px;
      }
    }
  }
  .mission-left h1 {
    /* width: 70%; */
    letter-spacing: 0.25em;
  }
  .our-mission {
    font-size: 12rem;
    font-weight: lighter;
    color: white;
    margin-top: 30px;
    margin-bottom: 0;
    padding-bottom: 50px;
    overflow: hidden;
    white-space: nowrap;
  }
  .our-mission-inview {
    font-size: 12rem;
    font-weight: lighter;
    color: white;
    margin-top: 30px;
    margin-bottom: 0;
    padding-bottom: 50px;
    animation: slide;
    animation-duration: 2s;
    overflow: hidden;
    white-space: nowrap;
  }
  @keyframes slide {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  .view-more {
    margin-top: 50px;
    a {
      padding-left: 10px;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: black;
    }
    img {
      max-width: 15px;
      margin: 0;
      display: block;
    }
  }
  .arrow-container {
    background-color: black;
    padding: 5px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .view-more:hover {
    a {
      padding-left: 5px;
    }
    .arrow-container {
      height: 45px;
      width: 45px;
      margin: 7.5px;
    }
  }
  @media screen and (max-width: 1170px) {
    .mission-left {
      width: 45%;
    }
    .mission-right {
      width: 55%;
    }
  }
  @media screen and (max-width: 1000px) {
    .our-mission-inview {
      font-size: 10rem;
    }
    .our-mission {
      font-size: 10rem;
    }
  }
  @media screen and (max-width: 840px) {
    .our-mission-inview {
      font-size: 8rem;
    }
    .our-mission {
      font-size: 8rem;
    }
  }
  @media screen and (max-width: 630px) {
    .mission-left {
      margin-left: 0;
    }
    .our-mission {
      font-size: 8rem;
    }
    .our-mission-inview {
      font-size: 8rem;
    }
  }
  @media screen and (max-width: 600px) {
    .our-mission {
      color: rgb(239, 238, 238);
    }
    .our-mission-inview {
      color: rgb(239, 238, 238);
    }
  }
  @media screen and (max-width: 550px) {
    .our-mission {
      font-size: 7rem;
    }
    .our-mission-inview {
      font-size: 7rem;
    }
  }
  @media screen and (max-width: 500px) {
    .our-mission-inview {
      text-align: center;
    }
  }
  @media screen and (max-width: 400px) {
    .our-mission {
      font-size: 9rem;
    }
    .our-mission-inview {
      font-size: 9rem;
    }
  }
`;

const paragraph =
  "私たちはAIを活用し、様々な常識を打ち砕きます。\n最小の資本と人で最大の付加価値を創造し、\n関わる全ての人のより良い未来のために活動します。";
export const HomeMission = () => {
  const { ref, inView } = useInView();

  return (
    <HomeMissionStyle id="mission">
      <div className="mission-content">
        <div className="mission-left">
          <div className="mission-head">
            <div className="ring">
              <img src={ring} alt="ring" />
            </div>
            <p className="mission-title">Mission</p>
          </div>
          <h1>
            最先端技術で<br></br>関わる全ての人の<br></br>より良い未来のために
          </h1>
        </div>
        <div className="mission-right">
          <p>{paragraph}</p>

          {/* <div className="view-more">
              <Link to={"/mission/"}>
                <p>view more</p>
                <div className="arrow-container">
                  <img src={arrow} alt="arrow" />
                </div>
              </Link>
            </div> */}
        </div>
      </div>
      <p ref={ref} className={inView ? "our-mission-inview" : "our-mission"}>
        Our Mission
      </p>
    </HomeMissionStyle>
  );
};

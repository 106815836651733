import styled from "styled-components";
// import twitter from "../../image/twitter.png";
import { AnimatedText } from "../animation/AnimatedText";
import { TextVertical } from "../animation/textVertical/TextVertical";

const HomeTopStyle = styled.div`
  margin: auto 100px;
  min-height: calc(100vh - 80px);
  position: relative;
  h1 {
    font-size: 3rem;
    margin-bottom: 10px;
    white-space: nowrap;
  }
  .top-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .top-text {
    width: 300px;
  }
  .twitter-img {
    height: 40px;
    border-radius: 30px;
  }
  .twitter {
    position: absolute;
    bottom: 10vh;
    right: 0;
  }
  .social-media-link img:hover {
    border: grey 2px solid;
  }
  @media screen and (max-width: 969px) {
    text-align: center;
    .top-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .top-text {
      width: 700px;
      margin: auto;
    }
  }
  @media screen and (max-width: 630px) {
    min-height: 300px;
    .twitter-img {
      height: 30px;
    }
    .twitter {
      position: absolute;
      bottom: 5vh;
      right: 0;
    }
  }
  @media screen and (max-width: 500px) {
    .twitter-img {
      height: 20px;
    }
  }
`;

const text = "AIの力で、付加価値創造を";
const textH2 = "We create added value for everyone involved";
const textp =
  "私たちはAIサービス「diffferent sales」\nを用いてクライアントの課題解決を行います。";

export const HomeTop = () => {
  const h2FontSize = 1.7;
  const pFontSize = 1.2;

  return (
    <HomeTopStyle>
      <div className="top-content">
        <AnimatedText text={text} />
        <TextVertical
          text={textH2}
          delayTime={3}
          fontSize={h2FontSize}
          fontWeight={"bold"}
          marginBottom={10}
        />
        <TextVertical
          text={textp}
          fontSize={pFontSize}
          delayTime={3.5}
          whiteSpace={"pre-wrap"}
        />
      </div>
      {/* <div className="twitter">
        <a
          className="social-media-link"
          href="https://twitter.com/MarkDoor_1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="twitter-img" src={twitter} alt="twitter" />
        </a>
      </div> */}
    </HomeTopStyle>
  );
};
